%organism-banner{
    background-color: $core-color;
    padding-bottom: 75px;
    .region-banner{
        background-size: cover; 
        background-position: center;
        background-repeat: no-repeat;
        height: 365px;
        position: relative;

        @include RWD(mobile){
            height: 200px;
        }

        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.08) 24%,rgba(0,0,0,0.33) 72%,rgba(0,0,0,0.43) 100%);
            z-index: 1;
        }
        & > * {
            z-index: 2;
        }
        
        & #front_logout {
            display : flex;
            height : 100%;

            > .container {
                align-self: flex-end;
                z-index: 3;

                a {
                    margin-bottom : 20px;
                }
            }
        }
    }
    .block-container-page_title{ // Spé Drupal
        position: relative;
        height: 100%;
    }
    h1{
        @extend %atom-main-title;
        min-height: 100px;
        padding: 20px 0;
        line-height: 60px;
        color: $core-color-font;
        
        @include RWD(mobile){
            padding: 50px 20px;
        }
    }
    
    .region-nav-tools{
        @extend %container;
        display: flex;
        align-items: center;     
        height: 80px;
        border-bottom: 1px solid #fff;

        .block-container-breadcrumb{
            margin-right: auto;
        }

        @include RWD(mobile){
            height: 115px;
            flex-wrap: wrap;
            flex-flow : row wrap;

            .block-container-breadcrumb {
                flex-basis: 100%;
            }
        }
    }
}