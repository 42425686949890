%bone-form-structure{
    $gutter: 30;

    .form-group{
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        &:last-child{
            margin-bottom: 0;
        }
        &.cnil{
            margin-top: 20px;
        }

        & ~ .rte{
            margin-bottom: 20px;
        }
    }

    .formContent {
        margin-top: 25px;
    }

    // Inputs à la suite
    .webform-layout-box{
        display: flex;

        .form-group{
            width: 100%;
        }

        .form-group + .form-group{
            margin-left: 120px;
        }


        @include RWD(mobile){
            display: block;
            .form-group{
                width: 100%;

                & + .form-group{
                    margin-left: 0;
                }
            }
        }
    }
    
    //  Dates avec datepicker
    .webform-datepicker{
        @extend %molecule-select-group-inline;
        @include RWD(mobile){
            flex-wrap: wrap;

            > .form-group{
                margin-bottom: 10px !important;
            }
        }
    }

    // Type heure
    .type-webform-time{
        .webform-container-inline{
            @extend %molecule-select-group-inline;
        }
    }

    .form-label{
        width: 190px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        padding-top: 15px;
        font-size: 16px;
        color: #505050;
        text-transform: none;
    }
    .form-field{
        // width: calc(83% - #{$gutter / 2}px); 
        // margin-left: #{$gutter / 2}px;
        flex: 1;
        display: flex;
        flex-flow: row wrap;
        position: relative;

        .field-suffix{
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }

        .form-item{
            padding-top: 15px;
        }

        .help-block{
            margin-right: 20px;
            padding-top : 2px;
            font-size : 1.2rem;
        }
    }
    
    .form-infos{
        width: 100%;
        // margin-left: calc(17% + #{$gutter / 2}px);
    }

    .form-actions{
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > * {
            margin-left: 20px;
        }

        @include RWD(mobile){
            flex-direction: column;
            align-items: flex-end;

            & > *{
                margin-left: 0;

                & + * {
                    margin-top: 20px
                }
            }
        }
    }


    @include RWD(mobile){
        .form-group{
            display: block;
        }
        .form-label{
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            display: block;
        }
        .form-field{
            width: 100%;
            margin-left: 0;
        }
    }
}