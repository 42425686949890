%organism-header{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 655px;
    background-image: url('../images/background/bg-header-home.jpg');
    background-size: cover;
    margin-bottom: 45px;

    .header-link{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .header-wrapper{
        padding: 30px 30px 30px 255px;
        background-color: rgba(255,255,255, 0.93);
        box-shadow: $box-shadow;
        position: relative;
        z-index: 2;

        .menu-rwd-btn{
            display: none;

            height: 85px;
            width: 85px;
            background-image: url(svg-bg-uri($svg-dots, $core-color));
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 40px 40px;
        }
        .logo{
            position: absolute;
            top: 30px; left: 30px;
            height: 180px;
            width: 195px;

            a {
                height: 100%;
                width: 100%;
            }
            
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
        
        #searchHeader{
            width: 600px;
            height: 60px;
            background-color: #e1e2e3;
            margin-bottom: 19px;
            display: flex;
            padding-left : 40px; 

            input, button{
                display: block; 
                background-color: transparent;
                border: none;
                height: 60px;
            }
            input{
                flex: 1;
                color: #505050;
                font-size: 2rem;
                font-weight: 700;
                width: calc(100% - 60px);
            }
            button{
                width: 60px;
                height: 60px;
                background-image: url(svg-bg-uri($svg-loupe2, #8879b1));
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: 30px;
                transition: background 0.35s $t-bam;

                &:hover, &:focus{
                    background-image: url(svg-bg-uri($svg-loupe2, $accent-color));
                }
            }
        }

        .main-access{
            
            &_line{
                display: flex;
                justify-content: space-between;
                margin-bottom: 11px;

                a{
                    display: block;
                    height: 45px;
                    line-height: 4.5rem;
                    text-align: center;
                    margin: 0 9px;
                    background-color: #f30;
                    text-decoration: none;
                    opacity: 0.85;
                    transition: opacity 0.35s ease;

                    font-family: $site-font;
                    font-size: 2rem;
                    font-weight: 700;
                    padding: 0 10px;
                    color: #fff;

                    flex-grow: 1;
                    &:first-child{ margin-left: 0; }
                    &:last-child{ margin-right: 0; }

                    &:nth-child(1){background-color: #187860;}
                    &:nth-child(2){background-color: #147778;}
                    &:nth-child(3){background-color: #2b8095;}

                    &:hover, &:focus{
                        opacity: 1;
                    }
                }
                
                &:last-child{
                    margin-bottom: 0;
                    a{
                        &:nth-child(1){background-color: #5f74a0;}
                        &:nth-child(2){background-color: #5577a5;}
                        &:nth-child(3){background-color: #685291;}
                    }
                }
            }

        }
    }

    @include RWD(tablet){
        .header-wrapper{
            padding: 30px;

            .logo{
                position: static;
                margin: 0 auto;
                margin-bottom: 30px;
            }
        }
    }

    @include RWD(mobile){
        .header-wrapper{
            background: none;
            box-shadow: none;
            width: 100%;
            .logo{
                background-color: rgba(255,255,255, 0.93);
                box-shadow: $box-shadow;
                padding: 10px 10px 17px;
                width: 100%;
            }
            #searchHeader{
                width: 100%;
            }
            .main-access_line{
                flex-wrap: wrap;
                margin-bottom: 0;
                a{
                    width: 100%;
                    margin: 0 0 11px;
                }
            }
        }
    }

}

%organism-header-int{
    display: block;
    position: fixed;
    width: 100%;
    background-color: #fbfeff;
    z-index: 50;
    box-shadow: $box-shadow;
    
    & + #main-content{
        padding-top: 85px;
    }
    
    .header-wrapper{
        @extend %container;
        height: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo{
            width: 145px;
            display: flex;
            align-items: center;
            margin-right: 15px;

            a {
                height: 100%;
                width: 100%;
            }

            img{
                min-width: 100%;
            }
        }
        .search{
            position: absolute;
            top: 100%;
            width: 100%;
            left: 0;
            background-color: #e1e2e3;
            overflow: hidden;
            transition: height 0.35s ease;
            height: 0;

            &.active{
                height: 60px;
            }
            form{
                display: flex;
                justify-content: space-between;
            }
            input{
                height: 60px;
                line-height: 60px;
                color: #505050;
                font-size: 2rem;
                font-weight: 500;
                width: calc(100% - 90px);
                border: none;
                background: none;
            }

            button{
                width: 26px;
                height : 26px;
                align-self: center;
                background: none;
                background-image: url(svg-bg-uri($svg-loupe2, #8879b1));
                background-repeat: no-repeat;
            }


            &.visible{
                height: 60px;
            }
        }
        .main-access{
            padding: 20px 0;
            flex: 1;
            margin-left: 20px;
            
            &_line{
                display: flex;
                justify-content: space-between;

                a{
                    display: block;
                    height: 45px;
                    line-height: 4.5rem;
                    text-align: center;
                    margin: 0 9px;
                    background-color: #f30;
                    text-decoration: none;
                    opacity: 0.85;
                    transition: opacity 0.35s ease;

                    font-family: $site-font;
                    font-size: 2rem;
                    font-weight: 700;
                    padding: 0 10px;
                    color: #fff;

                    flex-grow: 1;
                    &:first-child{ margin-left: 0; }
                    &:last-child{ margin-right: 0; }

                    &:nth-child(1){background-color: #187860;}
                    &:nth-child(2){background-color: #147778;}
                    &:nth-child(3){background-color: #2b8095;}
                    &:nth-child(4){background-color: #5f74a0;}
                    &:nth-child(5){background-color: #5577a5;}
                    &:nth-child(6){background-color: #685291;}

                    &:hover, &:focus{
                        opacity: 1;
                    }
                }
            }
        }
        button.menu-rwd-btn{
            display: none;
            height: 85px;
            width: 85px;
            background-image: url(svg-bg-uri($svg-dots, #8879b1));
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 38px 38px;
            &.active{
                background-image: url(svg-bg-uri($svg-cross, #8879b1));
            }

        }
        button.search-btn{
            display: block;
            width: 45px;
            height: 45px;
            flex-shrink: 0;
            margin-left: 18px;
            background-color: #e1e2e3;
            background-image: url(svg-bg-uri($svg-loupe2, #8879b1));
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 26px 26px;
            transition: background 0.35s ease;
            &.active{
                background-image: url(svg-bg-uri($svg-cross, #8879b1));
            }
        }
    }


    @include RWD(tablet){
        width: 100%;
        .header-wrapper{
            padding: 0;
            .logo{
                margin-right: 0;
            }
            .main-access{
                position: absolute;
                top: 100%;
                width: 100%;
                left: 0;
                margin-left: 0;
                background-color: rgba(#fff, 0.85);
                padding: 15px 40px;
                opacity: 0;
                z-index: -1;
                transform: translateY(-100%);
                transition: all 0.35s ease;

                &.active{
                    display: flex;
                    transform: translateY(0);
                    opacity: 1;
                }

                &_line{
                    flex-wrap: wrap;
                    width: 100%
                }

                a{
                    width: 100%;
                    margin: 5px 0;
                }
            }
            button.menu-rwd-btn{
                display: block;
            }
            button.search-btn{
                width: 85px;
                height: 85px;
                background-color: transparent;
                background-size: 38px 38px;
            }
        }
    }

    @include RWD(mobile){
        
    }
}