%molecule-cookies{
    position: fixed;
    display: none;
    bottom: 0;
    right: 0;
    width: 300px;
    background-color: $second-color;
    z-index: 1000;
    padding: 20px;
    padding-right: 40px;
    font-family: $site-font;
    color: #fff;
    font-size: 1.3rem;
    line-height: 1.2em;

    #popup-text{
        display: flex;

        a{            
            font-family: $site-font;
            color: #fff;
            font-size: 1.2rem; 
            transition: all, 0.5s;
            text-decoration: underline;

            &:hover, &:focus{
                color: $accent-color;
            }       
        }
    }
    .find-more-button{
        margin-left: 3px;
        font-family: $site-font;
        color: #7c7c7c;
        font-size: 1.2rem; 
        transition: all, 0.5s;
        text-decoration: underline;
        display: none;

        &:hover, &:focus{
            color: $core-color;
        }       
    }

    .close,.agree-button{
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        opacity: 1;
        transition: all, 0.5s;

        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background-size: 18px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-cross, #fff) );

        .circle{
            transition: all, 0.5s;
        }

        &:hover, &:focus{
            .circle{
                fill: $core-color;
            }
            opacity: 0.7;
        }
    }

    @include RWD(mobile){
        padding: 15px 45px 15px 20px;
        .close,.agree-button{
            right: 20px;
        }
    }
}