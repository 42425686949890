.not-front .view-list:not(.view-maps):not(.view-actualites):not(.view-agenda):not(.view-nodehierarchy-children-teasers) .view-grid{
    @extend %molecule-grid-basic;  
    
    /* Vignettes */
    .grid-item{
        margin-bottom: 30px;
        display: flex;

        & > a, & > div{
            flex: 1;
            @extend %atom-vignette;
        }
        a{            
            &:hover, &:focus{
                @extend %animation-vignette;
            } 
        }
    }

    &.grid-list{
        margin-bottom: 20px;
        .vignetteContain{
            display: block;
            position: relative;
            width: 100%;
            text-decoration: none;
            padding: 10px 0 14px;
            transition: all 0.35s ease;
            border-bottom: 1px solid $core-color;

            &:after{
                content: '';
                width: 90px;
                height: 100%;
                display: block;
                position: absolute;
                right: 0;
                top:0;
                background-image: url(svg-bg-uri($svg-big-arrow, rgba($core-color, 0.75)));
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 28px 28px;
                transition: all 0.35s ease;
        }

            &:hover, &:focus{
                padding-left: 20px;
                background-color: #f2f2f2;

                &:after{
                    transform: translateX(20px);
                    background-image: url(svg-bg-uri($svg-big-arrow, $core-color));
                }
            }

            h3{
                @extend %atom-h3-rte;
            }
            .category{
                @extend %atom-h4-rte;
            }
        }
    }
}