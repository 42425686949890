%atom-link-rte{
    text-decoration: underline;
    transition: color, 0.5s;
    color: $link-color;
    font-weight: 700;
        
    &:hover, &:focus{
        color: $core-color;
        text-decoration: underline;
    }

    // &[href^="http"]{
    //     &:after{
    //         vertical-align: super;
    //         font-size: smaller;
    //         text-decoration: none;
    //         content: '';
    //         width: 12px;
    //         height: 12px;
    //         display: inline-block;
    //         background-size: 10px;
    //         background-position: center;
    //         background-repeat: no-repeat;
    //         background-image: url( svg-bg-uri($svg-external, $core-color) );
    //     }
    // }
}
