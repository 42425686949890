// Yosemite
.class_identifier_sitemap {
	&.not-front .rte a {
		color: inherit;
		text-decoration: none;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	h2 a {
		color: $core-color;
	}
	h3 a {
		padding-left: 40px;
		color: $second-color;
	}
	h4 a {
		padding-left: 60px;
	}
	h5 {
		padding-left: 80px;
		margin-top: 30px;
	}
}

// Drupal
.page-sitemap #site-map {
	@extend %organism-sitemap-drupal;
}