%atom-btn-int{ 
    // display: inline-flex;
    // align-items: center;
    // justify-content: center;
    // padding: 0px 28px;
    // height: 50px;
    // text-decoration: none;
    // position: relative;
    // cursor: pointer;
    // transition: all $t-bam 0.3s;

    display: inline-block;
    position: relative;

    vertical-align: middle;

    padding: 12px 15px;

    cursor: pointer;
    background: $accent-color;

    text-decoration: none;
    color: $second-color;
    text-align: center;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    transform: perspective(1px) translateZ(0);

    transition: color 0.3s $t-bam;


    .btn-text{
        position: relative;
        font-family: $site-font;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
    .btn-arrow{
        flex-shrink: 0;
    }
    .flexbox{
        width: 100%;
        z-index: 1;
    }
    &:before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: scaleY(1);
        transform-origin: 50%;
        transition: transform 0.3s $t-bam;
    }

    &:hover, &:focus{
        &:before{
            transform: scaleY(0);
        }
    }

    &--core{
        @extend %atom-btn-int;
        background-color: $accent-color;
        color: #fff;
        &:before{
            background-color: $teal-color;
        }
    }
    &--inverted{
        @extend %atom-btn-int;
        background-color: $teal-color;
        color: #fff;
        &:before{
            background-color: $accent-color;
        }
    }
    &--white{
        @extend %atom-btn-int;
        background-color: #FFF;
        
        &:before{
            background-color: $second-color;
        }
        &:hover, &:focus{
            .btn-text{
                color: #FFFFFF;
            }
        }
    }
}

//~#BUTTONS
.btn-int--core{
    @extend %atom-btn-int--core;
}

.btn-int--inverted{
    @extend %atom-btn-int--inverted;
}

.btn-int--white{
    @extend %atom-btn-int--white;
}
//#BUTTONS