%molecule-map-itinerary{
    background-color: #FFFFFF;
    padding: 0 20px;

    .itinerary-head{
        display: flex;
        align-items: center;
        justify-content: center;

        // Retour aux catégories
        button{
            @extend %text-form-label;
            @extend %head-map-module;
            font-family: $site-font;
            padding: 20px 0px 10px 50px;
            display: block;
            position: relative;
            text-align: left;
            width: 100%;
            @extend %icon-back-map;
        }
    }
    .form-group{
        margin-bottom: 20px;

        & + .form-group{
            position: relative;

            &:before{
                content: '';
                position: absolute;
                top: -10px;
                width: 100%;
                left: 0;
                background-color: #f5f5f5;
                height: 1px;
            }
        }
    }
    // Form Actions
    .form-actions{ 
        display: flex;
        justify-content: flex-end;

        // Submit
         [type="button"]{
             @extend %atom-btn-int--inverted; 
         }
        //  Reset
        #form-maps-button-reset-itinerary{
            padding: 0;
            height: 50px;
            width: 50px;
            margin-right: 10px;
            .flexbox{
                height: 50px;
                &:before{
                    content: '';
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    background-size: 30px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url( svg-bg-uri($svg-map-reset, #FFFFFF) );
                }
            }
            .btn-text{ 
                font-size: 0;
            }
        }
    }



    // Itinéraire A suivre
    .adp-directions{
        word-break: break-word;
        b{
            font-weight: 600;
            color: $core-color;
        }
    }
    .adp-placemark{
        border: none;
        tbody > tr > td:first-child{
            padding-left: 10px;
            padding-right: 19px;
        }
        td{
            padding: 5px;
        }
    }
    .adp-substep{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .adp-substep:nth-child(2){
        color: $second-color;
        font-weight: bold;
    }
}