%molecule-share-this{
    display: flex;
    justify-content: space-around;
    .share-item{
         margin-right: 25px;

        a{
            font-size: 0;
            text-decoration: none;
            display: block;
            &:before{
                display: inline-block;
                font-size: 1.6rem;
                color: $core-color-font;
                transition: all, 0.5s;
                font-family: 'FontAwesome';
                width: 33px;
                height: 33px;
                text-align: center;
                line-height: 33px;
                background-color: #9473b1;
                border-radius: 50%;
            }
            &:hover, &:focus{
                &:before{
                    background-color: #56baa2;
                } 
            }
        }

        &.share-facebook{
            a:before{
                content: '\f09a';
            }
        }
        &.share-twitter{
            a:before{
                content: '\f099';
            }
        }
        &.share-googleplus{
            a:before{
                content: '\f0d5';
            }
        }

        &.share-rss {
            order : -1;
            a:before{
                content: "\f09e";
            }
        }

    }

    @include RWD(mobile){
        flex: 3;
    }

}