.gmap-basic, .event-gmap{ 
    position: relative;
    overflow: hidden;
    .map{
        height: 500px;
        @include RWD(mobile){
            height: 250px;
        }
    }

    .gmap-infowindow{
        @extend %atom-map-infowindow;
        position: absolute;
        top: 0; right: 0;
        height: 100%;
        width: 400px;
        max-width: 100%;
        transform: translateX(100%);
        transition: all, 0.5s;

        @include RWD(mobile){
            width: 100%;
        }

        &.opened{
            transform: translateX(0);
        }
    }
}