%atom-form-checkboxes{
    position: absolute;
    opacity: 0;
    & + label{
        display: flex;
        align-items: center;
        margin-right: 40px;
        &:before{
            content: '';
            display: block;
            height: 15px;
            width: 15px;
            background-color: #ffffff;
            border: 1px solid #505050;
            border-radius: 5px;
            margin-right: 10px;
        }
    }
    &:checked + label{
        &:before{
            background-image: url( svg-bg-uri($svg-check, $core-color) );
            background-size: cover;
        }
    }
}