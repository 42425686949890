%organism-comments{
    @extend %organism-rte; 
    @extend %container-form;
    clear: both;
    border-top: solid 1px $core-color;
    padding-top: 30px;
    padding-right: 170px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    form{
        @extend %molecule-form-components;
        @extend %bone-form-structure;
    }

    .comment-block-title{
        display: none;
    }

    .comment{
        display: flex;
        flex-wrap: wrap;
        max-width: 580px;
        padding: 20px 15px;
        border: solid 1px #d0d0d0;
        position: relative;
        & > * {
            width: 100%;
        }

        h3{
            order: 2;
        }
        .submitted{
            order: 0;
            color: #d0d0d0;
            font-size: 12px;
            font-size: 1.2rem;
            text-align: right;

            a{
                display: none;
            }
        }
        .content{
            order: 5;
        }
        .links{
            order: 20;
        }
    }
}