%organism-home-facebook{
    flex: 1;
    max-width: 500px;
    margin-right: 50px;
    
    .block-title{
        @extend %quark-underpill_cold;
    }
    .block-content{
        height: calc(100% - 66px);

        a{
            display: block;
            width: 100%;

            img{
                max-width: 100%;
            }
        }
    }

    @include RWD(tablet){
        margin-right: 25px;
    }

    @include RWD(mobile){
        max-width: 100%;
        width: 100%;
        margin-right: 0;
        flex: none;
    
        .block-content{
            max-width: 500px;
            margin: 0 auto;
        }
    }

}