#js-required{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: #000000;
    color: #ffffff;
    padding: 20px;
    font-size: 1.4rem;
    font-family: $main-font;
    display: none;
    animation: js_appear 2s 2s;
    animation-fill-mode: forwards;
    opacity: 0;

    @keyframes js_appear{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    body.no-js &{
        display: block;
    }

    .close{
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-close, #FFFFFF) );
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
