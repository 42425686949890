.not-front .view-maps .view-grid{
    @extend %molecule-grid-basic;  
    
    margin-top : 30px; 
    /* Vignettes */
    .grid-item{
        margin-bottom: 30px;
        display: flex;

        & > a, & > div, .map-trigger-full{
            flex: 1;
            @extend %atom-vignette;
        }
        a{            
            &:hover, &:focus{
                @extend %animation-vignette;
            } 
        }
        .view-full-contain{
            display: none;
        }
    }

    // View Full
    #view-full{
        display: none;
        .view-full-contain{
            @extend %atom-vignette--map-full;
        }
    }
}