.not-front .view-agenda .view-grid {
	@extend %molecule-grid-basic;
	/* Vignettes */
	.grid-item {
		min-height: 500px;
		display: flex;
		justify-content: stretch;
		margin-bottom: 30px;
		flex-flow: column nowrap;
		& > a,
		&--no-link {
			height: 100%;
			@extend %atom-vignette;
		}
		a {
			&:hover,
			&:focus {
				@extend %animation-vignette;
			}
		}
		> .ensavoirplus {
			display: none;
		}
	}
}