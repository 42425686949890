%molecule-quote{
    clear: both;
    max-width: 850px;
    margin: 0 auto;
    margin-bottom: 40px;
    
    &:before{
        content: '';
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
        width: 75px;
        height: 50px;
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-quote1, $core-color) );
    }
    .quote-content{
        padding: 10px 5px;
        border: solid 1px $core-color;
        border-left: none;
        border-right: none;
        .quote-author{
            font-style: italic;
            text-align: right;
        }
        .quote-text{
            margin-bottom: 40px;
            text-align: center;
            & > *:last-child{
                margin-bottom: 0;
            }
            & > *:last-child{
                margin-bottom: 0;
            }
        }

    }
}