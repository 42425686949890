%organism-home-youtube{
    flex: 1;
    width: auto;
    min-width: 400px;

    .block-title{
        @extend %quark-underpill_warm;
    }
    .block-content{
        border: 1px solid #b3b3b3;
        padding: 7px 10px;
        iframe{
            margin-bottom: 30px;
            height: 500px;
            width: 100%;
        }
        .thumbnails-wrapper{
            display: flex;
            justify-content: space-between;

            .thumbnail{
                width: calc(25% - 11px);
                button{
                    padding-top: 100%; 
                    width: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                &.active{
                    display: none;
                }
            }
        }
    }

    @include RWD(mobile){
        max-width: 100%;
        min-width: 100%;
        width: 100%;

        .block-content{
            iframe{
                height: 50vw;
                margin-bottom: 10px;
            }
            .thumbnails-wrapper .thumbnail{
                width: calc(25% - 7px);
                margin-bottom: 14px;
            }
        }
    }

}