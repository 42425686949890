%molecule-table{
    clear: both;
    width: 100%;
    border-collapse: separate;
    padding: 20px;
    border: 1px solid #b3b3b3;
    caption{
        visibility:hidden;
    }
    td{ 
        padding-top:15px;
        padding-bottom:15px;
        padding-left:15px;
        padding-right:15px;
        text-align: center;
        font-size: 1.5rem;
        background-color: #f5f5f5;
        & > *:last-child{
            margin-bottom: 0;
            font-family : $main-font;
            font-weight: 700;
            font-size : 18px;
            color : $link-color;
        }
    }
    th{
        padding-top:15px;
        padding-bottom:15px;
        padding-left:15px;
        padding-right:15px;
        text-align: center;
        padding: 16px;
        background-color: $core-color;
        text-transform: uppercase;

        & > *:last-child{
            margin-bottom: 0;
            color:  $core-color-font;
            font-family: $site-font;
            font-weight : 700;
            font-size : 17px;
        }
    }
    tbody > tr{
        & > td, & > th{
            border-bottom: 10px solid #fff;
        }
        &:last-child{
            & > td, & > th{
                border-bottom: none;
            }
        }
        &:nth-child(even){
            background-color: $core-color-light;
        }
    }

    &--responsive{
        @extend %molecule-table;
        @media(max-width: 39.9375em){
            &.tablesaw-stack td{
                display: flex;
                flex-wrap: wrap;
            }
            &.tablesaw-stack td .tablesaw-cell-label, &.tablesaw-stack th .tablesaw-cell-label{
                // width: auto;
                hyphens: auto;
                word-wrap: break-word;
            }
            b{
                font-weight: 600;
                color: #212121;
            }
            td, th{
                text-align: left;

                &:nth-child(even){
                    background-color: $core-color-light;
                }
            }
            tr{
                display: block;
                border-color: $core-color;
                border-width: 1px;
            }
        }
    }
}

