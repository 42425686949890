.not-front .view-list,
.not-front.page-search-site{
    // Résultats 
    .view-header{
        margin-bottom: 20px;
    }
    
    // Date separator spé Agenda
    .view-date-separator{
        @extend %atom-h2-rte;
        margin-bottom: 20px;
    }

    // Liste geoloc
    &.view-maps{
        .view-header{
            margin-top: 20px;
            margin-bottom: 20px;
            & + .view-grid{
                margin-top: 0;
            }
        }
    }
    
    // Pagination
    .pagination{
        margin-bottom: 40px;
    }

    // Apparence RTE Drupal 
    .view-recherche{
        #main-content ul {
            @extend %atom-ul-rte;
        }
    }
}