.front{
    background-color: #fff;
    .home-wrapper{
        @extend %container;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .block-home{
        
        margin-bottom: 45px;

        .block-title{
            @extend %text-h1;
            font-size: 4rem;
            margin-bottom: 8px;
            color : $second-color;
        }

        &.block-facebook{
            @extend %organism-home-facebook;
        }
        &.block-youtube{
            @extend %organism-home-youtube;
        }
        &.block-acces-rapides{
            @extend %organism-home-accesrapides;
        }
    }
}