%atom-form-radios{
    position: absolute;
    opacity: 0;
    & + label{
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 25px;
        margin-right: 40px;

        &:before{
            content: '';
            display: block;
            height: 15px;
            width: 15px;
            border: solid 1px #505050;
            background-color: transparent;
            border-radius: 50%;
            margin-right: 13px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
        }
        &:after{
            content: '';
            display: block;
            height: 11px;
            width: 11px;
            background-color: transparent;
            border-radius: 50%;
            position: absolute;
            left: 2px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
    &:checked + label{
        &:after{
            background-color: #505050;
        }
    }
}