.not-front{
    main{
        @extend %text-basis-rte;
    }
    .hat{
        margin-bottom: 30px;
    }

    #main-content > .container{
        background-color: #fff;
        padding: 40px;
        transform: translateY(-75px);

        @include RWD(mobile){
            padding: 20px;
        }
    }

    // Publié le d'une actu
    .actu-published{
        text-align: right;
        font-style: italic;
        color: $core-color;
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    #fiche-annuaire{
        h2{
            @extend %atom-h2-rte;
            color: #505050;
        }
        h3{
            @extend %atom-h3-rte;
            @extend %quark-underpill_cold;
            margin-bottom: 20px;
            color: $core-color;
        }

        .informations-pratique{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;
            ul{
                width: 50%;
                li{
                    margin-bottom: 10px;
                    font-size: 16px;
                    line-height: 24px;
                    color: #505050;
                    strong{
                        display: block;
                    }
                }
            }
            @include RWD(mobile){
                ul{
                    width: 100%;
                }
            }

            a {
                @extend %atom-link-rte;
            }
        }
    }
}