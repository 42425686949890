%molecule-audio-player{
    display: flex;
    width: 100%;
    max-width: 100%;
    background-color: #040404;
    margin: 0 auto;
    @include RWD(mobile){
        display: block;
        padding: 20px;
    }

    audio{
        display: none;
    }

    // Visuel
    .audio-visu{
        img{
            height: 140px;
            width: 140px;
        }
        @include RWD(mobile){
            margin-bottom: 20px;
        }
    }

    // Partie droite infos
    .audio-infos{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 5px 10px;
    }

    .audio-title{
        font-weight: 600;
        font-size: 24px;
        font-size: 2.4rem;
        color: #1c1c1c;
        margin-bottom: 10px;
    }
    .audio-artist{
        font-weight: 400;
        font-size: 16px;
        font-size: 1.6rem;
        color: #1C1C1C;
        margin-bottom: 5px;

        @include RWD(mobile){
            margin-bottom: 20px;
        }
    }

    // Lecteur audio
    .audio-player{
        display: flex;
        width: 100%;
        max-width: 100%;

        // Bouton playpause
        .player-control{
            cursor: pointer;
            margin-right: 20px;
            height: 25px;
            width: 25px;
            transition: all, 0.5s;
            .flexbox{
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .btn-play{
                @extend %icon-play;
            }
            .btn-pause{
                @extend %icon-pause;
                margin: 0 auto;
                display: none;
            }
            .hidden{
                display: none;
            }

            &.playing{
                .btn-play{
                    display: none;
                }
                .btn-pause{
                    display: flex;
                }
            }
            &:hover, &:focus{
                opacity: 0.5;
            }
        }

        // Vue bar + temps
        .player-view{
            flex: 1;
            position: relative;
            .player-timing{
                display: flex;  
                justify-content: space-between;
                margin-bottom: 5px;
                position: absolute;
                width: 100%;

                .player-time-current{
                    font-size: 1.1rem;
                    color: #FFF;
                    position: absolute;
                    left: 0;
                    top: 4px;
                }
                .player-time-total{
                    font-size: 1.1rem;
                    color: #FFF;
                    position: absolute;
                    right: 0;
                    top: 4px;
                }
            }
            .player-progress-bar{
                height: 10px;
                background-color: #525252;
                position: relative;
                overflow: hidden;
                margin: 7px 40px;
                width: auto;

                input[type="range"]{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    margin: 0;
                    transform: translateY(-50%);
                    opacity: 0;
                    cursor: pointer;
                    z-index: 10;
                }
                .player-running-bar{
                    background-color: $accent-color;
                    position: absolute;
                    top: 0;
                    left: 0; 
                    height: 100%;
                    width: 0%; 
                }
            }
        }
    }
}