%molecule-search-filters{
    display: block;
    margin-bottom: 40px;

    @extend %bone-form-structure;
    @extend %molecule-form-components;

//  Agenda
    &--agenda{
        @extend %molecule-search-filters;
        padding: 30px;
        background-color: #f2f2f2;
        form > div{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .form-group{
            flex-basis: 48%;
            height: 50px;
            display: flex;
            flex-wrap: wrap;
            @include RWD(mobile){
                height: auto;
            }

            @include RWD(tablet){
                flex-basis : 100%;
            }
            &:nth-of-type(2) {
                order : 3;
            }
            &:nth-of-type(3) {
                order : 2;
                // padding-left : 60px;
           }
           &:nth-of-type(4) {
            // padding-left : 60px;
            order : 4;
           }
        }
        .form-actions {
            order : 5;
        }

        label.form-label:not(.option){
            width: 130px;
            margin: 0;
            font-size: 1.6rem;
            color: #505050;
            font-weight: 400;
            text-transform: none;
            line-height: 50px;
            @include RWD(mobile){
                width: 100%;
            }
        } 
        .form-field{
            flex: 1;
            margin: 0;
            @include RWD(mobile){
                width: 100%;
            }
            input:not([type="radio"]):not([type="checkbox"]):not(.webform-calendar):not([type="file"]), .customSelectContain{
                width: 100%;
                height: 50px;
            }
        }
        .form-infos{
            margin-left: 0;
        }
        .form-actions{
            width: 100%;
            
            button{
                text-transform: uppercase;
                padding-left: 25px;
                padding-right: 25px;
                @include RWD(mobile){
                    width: 100%;
                    margin-bottom: 20px;
                }  
            }
        }
    }
}