%molecule-form-table{
    .form-label{
        display: none;
    }
    .form-field{
        margin: 0;
    }
    input{
        margin: 0; 
        height: auto;
        width: auto;
    }
} 