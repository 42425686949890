%atom-sliders-dot--wambrechies-duo{
    margin: 5px 7px;
    span{
        background-color: #2E2E2E;
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 5000px;
        transition: all, 0.5s;
    }

    &.active{
        cursor: auto;
        span{
            background-color: #E4E4E4;
        }
    }
    &:not(.active){
        &:hover, &:focus{
            span{
                background-color: #E4E4E4;
            }
        }
    }
}