%organism-sitemap-drupal {
	@extend %container;
	@extend %organism-rte;
	h2.title {
		margin-top: 50px;
		margin-bottom: 40px;
		color: #2D2D2D;
		@extend %text-h1;
		@include RWD(mobile) {
			margin-top: 40px;
			margin-bottom: 30px;
		}
	}
	a {
		display: block;
		line-height: normal !important;
		&:hover,
		&:focus {
			color: $second-color !important;
		}
	}
	h5 {
		margin-top: 30px;
		margin-left: 80px;
	}
	.site-map-front-page {
		.content {
			a {
				padding-left: 50px;
				@extend %atom-h2-rte;
				@include RWD(mobile) {
					padding-left: 20px;
				}
			}
		}
	}
	.site-map-menu {
		li {
			&:before {
				display: none !important;
			}
		}
	}
	.content > .site-map-menu {
		// lvl 1
		& > li {
			& > a {
				margin-top: $mt-h2;
				margin-bottom: $mb-h2;
				@extend %atom-h2-rte;
			} // lvl 2
			& > ul > li {
				a {
					margin-top: $mt-h3;
					margin-bottom: $mb-h3;
					@extend %text-h3;
				} // lvl 3
				& > ul > li {
					a {
						padding: 0;
						margin-top: $mt-h4;
						margin-bottom: $mb-h4;
						@extend %atom-h4-rte;
					}
				}
			}
		}
	}
}