%atom-hat-default{
    @extend %molecule-inlines-rte;
    @extend %text-hat;
    
//    font-weight: 700;
//    color: #2c2c2c;
    & > * {
        font-weight : 700;
        font-family: $site-font;           
        color : #2c2c2c;
        line-height: 1.2em;
        font-size : 2.5rem;
    }
}