%molecule-media{
    .media-body{
        padding: 20px;
        background-color: #f7f7f7;
    }
    .media-content{
        display: block;
    }
    .media-ratio{
        margin-bottom: 20px;
        width: 100%;
    }
    .media-actions{
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .media-heading{
        margin: 0;
        padding-left: 0;
        font-family: $site-font;
        font-weight: 700;
        font-size : 20px;
        margin-top: $mt-h3;
        color: $second-color;
        // Extend text media heading
        & + .media-description{
            margin-top: $mt-h3;
        }
    }
    .media-description{
        @extend %text-basis-rte;
        & > *:last-child{
            margin-bottom: 0;
        }
        padding: 0 20px;
    }

    .media-transcription-button{
        font-size: 1.5rem;
        font-weight: 500;
        .flexbox{
            display: flex;
            align-items: center;
        }

        .btn-arrow{
            content: '';
            margin-left : 10px;
            font-weight: bold;
            display: inline-block;
            flex-shrink: 0;
            width: 15px;
            height: 15px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-map-chevron, #69655E) );
            transform: rotate(90deg);
            transition: .3s;
        }

        &.opened{
            .btn-arrow{
                transform: rotate(270deg);
            }
        }
    }
    .media-transcription{
        @extend %molecule-transcription-general;
    }
}