#slider--full, .slider--full-wrapper{
    @extend %molecule-slider--full;

    @include RWD(mobile) {
        .slider-content {
            position: relative;
            padding: 20px;
            border: 1px solid #b3b3b3;
            overflow: hidden;
            padding-bottom: 70px;

            ul.slider {
                position: absolute;
                left: 0;
                border: none;
            }
        }

        .slider-text {
            position: relative;
            top: 0;
        }
    }

}