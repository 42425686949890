%organism-footer{
    
    font-size: 1.6rem;
    color: $second-color;
    background-image: url('../images/background/bg-page.png');
    background-position: 100% calc(100% - 60px);
    background-repeat: no-repeat;

    .container{
        display: flex;
        flex-wrap: wrap;
    }

    .footer-left{
        width: 25%;
    }
    .logo{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 23px;
        margin-bottom: 22px;
        position: relative;
        
        img{
            width: 170px;
            margin-bottom: 25px;
        }
        
        &:before,  &:after{
            content: '';
            position: absolute;
            display: block;
            width: 23px;
            height: 23px;
            bottom: 0;
            border-radius: 50%;
        }
        &:before{ 
            background-color: $violet-color;
            left: 0;
        }
        &:after{ 
            background-color: $green-color;
            left: 23px;
        }
    }

    .address-footer{
        @extend %quark-underpill_cold;
        margin-bottom: 28px;
        &:after{
            top:5px;
        }
        h2{
            font-family: $site-font;
            font-weight: 900;
            &:first-of-type{
                font-size: 2.6rem;
                line-height: 3.1rem;
                margin-bottom: 11px;
            }
            &:last-of-type{
                font-size: 2.4rem;
                line-height: 2.9rem;
                margin-top: 11px;
            }
        }
        p{
            margin: 0;
            line-height: 1.9rem;
            
        }
    }
    
    .opening-footer{
        margin-bottom: 28px;
        h2{
            font-size: 2.2rem;
            font-family: $site-font;
            font-weight: 900;
            line-height: 2.3rem;
            margin-bottom: 11px;
        }
        p{
            line-height: 1.9rem;
        }
    }
    
    .kiosque-btn-footer{
        @extend %atom-btn-int--core;
        height: 110px;
        width: 110px;
        border-radius: 55px;
        display: flex;
        justify-content: center;
        align-items: center; 
        color: #fff;
        text-decoration: none;
        font-size: 2.2rem;
        font-family: $site-font;
        font-weight: 900;
        overflow:hidden;
        &:before{
            background-color: $violet-color;
        }
    }   
    
    
    .footer-right{
        width: 75%;
    }
    .link-list-footer-wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
        
        .link-list-footer{
            width: 33.33332%;
            margin-bottom: 15px;
            padding-left: 17px;
            padding-right: 15px;
            border-left: 1px solid #dbdbdb;

            a{
                color: $link-color;
                text-decoration: none;
                &:hover, &:focus{
                    text-decoration: underline;
                }
            }

            & > a{
                line-height: 60px;
                font-size: 1.8rem;
                font-weight: 500;
            }
            ul{
                padding-left: 20px;
            }
            
            ul > li {
                font-size: 1.6rem;
                line-height: 2.8rem;
                list-style-type: disc;                
            }
            
            &:nth-child(3n+1){
                border-left: none;
            }
            
        }

    }

    .footer-bottom{
        width: 100%;
        height: 60px;
        position: relative;

        ul{
            margin: 0 auto;
            text-align: center;
            li{
                display: inline-block;
                a{
                    display: inline-block;
                    height: 60px;
                    line-height: 6rem;
                    margin: 0 10px;
                    font-size: 1.6rem;
                    color: $link-color;
                    text-decoration: none;
                    &:hover, &:focus{
                        text-decoration: underline
                    }
                }
            }
        }

        .link-telmedia{
            position: absolute;
            right: 0;
            top: 0;
            height: 60px;
            line-height: 6rem;
            font-size: 1.2rem;
            text-decoration: none;
            color: $link-color;

            span{
                color: $accent-color;
            }
            &:hover, &:focus, &:hover span, &:focus span{
                color: $violet-color;
            }
        }
    }

    @include RWD(tablet){
        background-image: url('../images/background/bg-page-tablet.png');
        
        .footer-left{
            width: 100%;
            position: relative;
            padding-left: 50%;
            margin-bottom: 25px;

            .logo{
                position: absolute;
                left: 0;
                top: 0;
                width: 50%;

                // &:before, &:after{
                //     content: none;
                // }

                img{
                    width: 260px;
                    height: 245px;
                    max-width: 75%;
                }
            }

        }
        .footer-right{
            width: 100%;
        }

    }   


    @include RWD(tablet_only){
        .footer-left .kiosque-btn-footer{
            width: 100%;
            height: 54px;
            line-height: 5.4rem;
            border-radius: 0;
        }

        .footer-right.link-list-footer-wrapper{
            padding-bottom: 40px;
        }

        .footer-bottom{
            padding-left: 10px;
            ul{
                text-align: left;
            }
        }
        
 
    }


    @include RWD(mobile){
        background: none;
        .container{
            padding: 0;
        }
        .footer-left{
            padding: 0;
            text-align: center;

            .logo{
                position: relative;
                width: 100%;
                justify-content: center;
                img{
                    display: inline-block;
                    margin-left: 15px;
                }
                &:before{
                    left: calc(50% - 23px);
                }
                &:after{
                    left: 50%;
                }
            }

            .address-footer:after{
                margin: 5px auto 0;
            }

            .opening-footer{
                margin-bottom: 35px;
            }

            .kiosque-btn-footer{
                margin: 0 auto;
                width: 177px;
                height: 177px;
                border-radius: 50%;
                font-size: 3rem;
            }
        }

        .footer-right.link-list-footer-wrapper{
            background-image: url('../images/background/bg-page-tablet.png');
            background-position:0 100%;
            background-repeat: no-repeat;
            display: block;
            text-align: center;
            padding-bottom: 220px;

            .link-list-footer{
                width: 100%;
                text-align: left;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        .footer-bottom{
            text-align: center;
            padding-top: 5px;
            padding-bottom: 20px;
            height: auto;
            ul{
                margin-top: 10px;
                li{
                    a{
                        line-height: 2.3rem;
                        height: auto;
                    }
                }
            }
            .link-telmedia{
                display: block;
                position: static;
                line-height: 3rem;
                height: auto;
                margin-bottom: 10px;
            }
        }
    }
}