%quark-underpill{
    &:after {
        content: '';
        display: block;
        position: relative;
        top: -2px;
        height: 8px;
        transform: skew(-20deg);
        border-radius: 20px;
        width: 60px;
    } 
    &_cold:after{
        @extend %quark-underpill;
        background: linear-gradient(90deg, #66C2A9, #73C0BE);  
    }
    &_warm:after{
        @extend %quark-underpill;
        background: linear-gradient(90deg, #9373B1, #E8327C);
    }
}