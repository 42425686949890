%molecule-media-audio{
    .media-text {
        display: flex;
        flex-flow : row wrap;

        .media-heading {
            margin-left : 10px;
        }
        .media-description {
            flex-basis : 100%;
            margin-left : 40px;
        }
    }
    .media-content{
        display: flex;
        flex-wrap: wrap;
    }

    .media-ratio{
        text-align: center;
        margin-top: 30px;

        audio{
            max-width: 100%;
        }
    }
}
