%molecule-pagination{    
    width: 100%;
	text-align: center;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.pagin-item{
		display: inline-block;
		color: inherit;

		&:before{
			display: none !important;
		}

		a{
			position: relative; 
			z-index: 2;
		}

		&.pagin-prev{
			margin-right: auto;
		}

		&.disabled{
			pointer-events: none;
			display:none;
		}
		&.is-active{
			pointer-events: none;
		}
		&.pagin-next{
			margin-left: auto;
		} 
		&.pagin-first{
			margin-left: 0;
		} 

		/**
			Pagination "chiffres"
		*/
		&:not(.pagin-next):not(.pagin-prev):not(.pagin-first):not(.pagin-last){
			& > a, & > div{
				text-decoration: none;
			}
			.btn-text{
				font-family: $site-font;
				font-size: 1.5rem;
				color: $text-color;
				font-weight: 600;
				padding: 3px;
			} 
			&.is-active{
				text-decoration: underline;
			}
			a:hover, a:focus{
				.btn-text{
					color: $core-color-font;
				}
				background-color: $core-color;
			}
		}
		/**
			Pagination "Boutons"
		*/
		&.pagin-next,
		&.pagin-prev,
		&.pagin-first,
		&.pagin-last{
			&:not(.is-active):not(.disabled){			
				& > div, & > a{
					@extend %atom-btn-int--core;
					height: 50px;
					padding: 12px 20px;
					text-transform: uppercase;
				}
			}
			&.is-active, &.disabled{
				& > div, & > a{
					@extend %atom-btn-int--white; 
					padding: 0 15px;
					text-decoration: none;
				}
			} 
		}
	}
	@include RWD(tablet){
		.pagin-first,
		.pagin-last{
			display: none !important;
		}
	}
	@include RWD(mobile){
		.btn-text{
			display: flex;
			align-items: center;
		}
		.pagin-item:not(.pagin-next):not(.pagin-prev){
			display: none;
		}
		.pagin-next{
			margin-left: auto;
		}
		.pagin-next, .pagin-prev{
			.btn-text{
				font-size: 0 !important;
			}
		}
		.pagin-prev .btn-text:after{
			content:'Prec'; 
			font-size: 16px;
			font-size: 1.6rem;
		}
		.pagin-next .btn-text:before{
			content:'Suiv';
			font-size: 16px;
			font-size: 1.6rem;
		}
	}
}