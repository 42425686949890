%atom-form-input{
    width: 100%;
    height: 50px;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 0;
    @extend %text-form-input;
    padding: 0 15px; 

    &:focus{
        border-color: $core-color;
    }

    &[type="file"]{
        padding-top: 15px;

        .help-block{
            padding-top: 15px;
            margin: 0;
        }
    }
} 