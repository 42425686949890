.not-front .view-nodehierarchy-children-teasers .view-grid{
    @extend %molecule-grid-basic;  
    
    /* Vignettes */
    .grid-item{
        margin-bottom: 30px;
        display: flex;

        & > a, & > div{
            flex: 1;
            @extend %atom-vignette;
        }
        a{            
            &:hover, &:focus{
                @extend %animation-vignette;
            } 
        }
    }
}