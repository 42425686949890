.not-front .grid-trombinoscope {
	@extend %molecule-grid-trombinoscope;
	.grid-item {
		margin-top: 40px;
		@extend %atom-vignette-trombinoscope;
		@include RWD(tablet) {
			margin-top: 30px;
		}
		&:nth-child(1),
		&:nth-child(2) {
			@include RWD(desktop) {
				margin-top: 0;
			}
		}
	}
}

.trombinoscope {
	@include RWD(mobile) {
		.figureContain img {
			margin-right: 1rem;
		}
	}
	tr {
		display: flex !important;
		@include RWD(mobile) {
			display: table-row !important;
		}
	}
	tbody > tr {
		& > td,
		& > th {
			width: calc(100% / 5);
			border: none;
			@include RWD(mobile) {
				width: 100%;
				background-color: #f5f5f5 !important;
			}
		}
	}
}