%atom-form-select2{
    // Attention, la liste déroulante est crée en JS après le footer, modifiable dans page-global.scss

    &:after { 
        position: absolute;
        top: 23px;
        right: 15px;
        content: '';
        display: block;
        height: 20px;
        width: 20px;
        background-size: 15px;
        background-position: center;
        background-repeat: no-repeat;
        transform:  translateY(-50%) rotate(90deg);
        background-image: url( svg-bg-uri($svg-arrow1, #7e7e7e) );
    }
    .select2-selection{
        @extend %atom-form-input;
        height: auto;
        min-height: 46px;
        padding: 0 5px 0px 5px;
        display: flex;
        align-items: center;
        padding-right: 40px;
    }
    &.select2-container--focus{
        .select2-selection{
            border-color: $core-color;
        }
    }
    .select2-selection__rendered{
        padding: 0;
        margin-bottom: 0;
    }
    .select2-selection__rendered li{
        display: flex;
        align-items: center;
        height: 30px;
        font-family: $site-font;
        color: $text-color;
        font-size: 1.4rem;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1.2;
        margin-bottom: 5px;

        &:before{
            display: none;
        }
    }
    .select2-search{
        width: 100%;
    }
    .select2-search__field{
        margin: 0 ;
        height: 50px !important;
        padding: 10px 10px 8px 10px!important;
        min-height: 0;
        width: 100% !important;
        border: none !important;
        &:hover, &:active, &:focus{
            border-color: transparent !important;
            background-color: transparent !important;
        }
    }
    // Quand une réponse est renseignée on cache le champ de recherche, il ne sert que pour le placeholder
    .select2-selection__choice + .select2-search{
        display: none;
    }
}