%organism-home-accesrapides{
    
    width: 100%;
    display: flex;
    justify-content: space-between;

    .link-wrapper{
        width: calc(50% - 25px);
        padding: 20px;
        background-color: $page-bg-color;
        a{
            @extend %atom-btn-int--core;
            font-family: 'Roboto';
            display: block;
            height: 60px;
            padding: 10px 0;

            .btn-text{
                font-size: 3.2rem;
                line-height: 4rem;
            }

            &:before{
                background-color: #368b8c;
            }
        }
    }

    @include RWD(tablet){
        .link-wrapper a .btn-text{
            font-size: 2.2rem;
        }
    }

    @include RWD(mobile){
        display: block;
        .link-wrapper{
            width: 100%;
            margin-bottom: 20px;
        }
    }
 }