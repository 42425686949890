%icon-arrow-select{
    $arrow-height: 10px;
    content: '';
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -2px;
    transform: rotate(45deg) translateY(-50%);
    height: 10px;
    width: $arrow-height;
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-left: none;
    border-top: none;
}

%icon-back-map{
    &:before{
        background-image: url( svg-bg-uri($svg-arrow1, $icon-color) );
        content: '';
        width: 50px;
        height: 25px;
        background-position: right center; 
        background-repeat: no-repeat;
        background-size: 20px;
        display: block;
        position: absolute;
        left: 0px; 
        top: 16px;
        transform: rotate(180deg);
    }  
    &:hover, &:focus{
        &:before{
            background-image: url( svg-bg-uri($svg-arrow1, $icon-color-active) );
        }
    }
}