%icon-play{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent #fff;
    display: block;

}
%icon-pause{
    justify-content: space-between;
    height: 16px;
    width: 12px;
    &:before{
        content: "";
        height: 100%;
        width: 4px;
        background-color: #fff;
        display: block;
    }
    &:after{
        content: "";
        height: 100%;
        width: 4px;
        background-color: #fff;
        display: block;
    }
}