%molecule-ariane{
    display: flex;
    flex-wrap: wrap;

    li, li a{
        font-size: 1.5rem;
        color: #fff;
        text-decoration: none;
    }
    li a{
        &:hover, &:focus{
            text-decoration: underline;
        }
    }
    li{
        &:after{
            content: '';
            @extend %quark-underpill_cold;
            width: 10px;
            height: 5px;
            top: -4px;
            display: inline-block;
            margin: 0 7px;
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
        .page.active{
            font-weight: 700;;
        }
    }

    @include RWD(mobile){
        display: none;
    }
}