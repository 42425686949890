%molecule-media-map{
    clear: both;
    .media-body{
        padding: 0;
    }
    .media-text{
        padding: 20px;
    }
    .media-ratio{
        margin-bottom: 0;
        position: relative;
        overflow: hidden;
    }
}