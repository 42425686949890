%molecule-print-this{
    display: flex;
 
    span{
        // margin-right: 10px;
        a{
            font-size: 0;
            text-decoration: none;
            display: block;
            &:before{
                display: inline-block;
                font-size: 1.6rem;
                color: $core-color-font;
                transition: all, 0.5s;
                font-family: 'FontAwesome';
                width: 33px;
                height: 33px;
                text-align: center;
                line-height: 33px;
                background-color: #9473b1;
                border-radius: 50%;
            }
            &:hover, &:focus{
                &:before{
                    background-color: #56baa2;
                } 
            } 
        }

        &.print_html{
            a:before{
                content: '\f02f';
            }
        }
        &.print_pdf{
            a:before{
                content: '\f1c1';
            }
        }

    }

}