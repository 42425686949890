$path: '../fonts/';
/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

////////////////////////// Supria Sans
// Black/Heavy
@font-face {
    font-family:'Supria Sans';
    src: url($path+'SupriaSans-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

// Bold
@font-face {
    font-family:'Supria Sans';
    src: url($path+'SupriaSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}    

//Medium
@font-face {
    font-family:'Supria Sans';
    src: url($path+'SupriaSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

////////////////////////// Roboto
// Bold
@font-face {
    font-family:'Roboto';
    src: url($path+'Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

// Medium
@font-face {
    font-family:'Roboto';
    src: url($path+'Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

// Regular
@font-face {
    font-family:'Roboto';
    src: url($path+'Roboto.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}