%organism-wi-kiosque{
    display: block;

    .vignette-grid{
    
        @include RWD(desktop){
            @include grid-distribution(3, 30, 'vignetteContain');                
        }

        .vignetteContain{
            width: calc((100% - 60px) / 3 - 1px);
            padding: 20px;
            background-color: #f2f2f2;
            margin-bottom: 30px;

            display: flex;
            justify-content: space-between;

            .vignette-wrapper{
                width: calc(50% - 10px);
                .vignette{
                    padding-top: 140%;
                    background-size: cover;
                    background-position: 50% 50%; 
                }
            }
            .vignetteTitle{
                width: calc(50% - 10px);
                position: relative;
                h2{
                    height: 142px;
                    & > div{
                        &:first-child{
                            margin-bottom: 25px;
                            color: $second-color;
                            font-size: 2.5rem;
                            font-weight: 700;
                            font-family: $site-font;
                            line-height: 1.2em;
                            word-wrap: break-word;
                        }
                        & + div{
                            font-size: 1.6rem;
                            line-height: 24px;
                            color: #69655e;
                        }
                    }
                }
                .btn{
                    @extend %atom-btn-int--core;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    display: block;
                    padding: 3px 0;
                    text-transform: uppercase;
                    
                    &.btn-read{
                        margin-bottom: 0;
                        bottom: 40px;
                    }
                    &.btn-download{
                        bottom: 0;
                    }
                }
            }

        }


    }

    @include RWD(tablet){
        .vignette-grid {
            @include grid-distribution(2, 30, 'vignetteContain');
            .vignetteContain{
                width: calc(50% - 15px);
                .vignetteTitle h2 > div:nth-child(2) {
                    display :none;
                }
            }
        } 
    }
    
    @include RWD(mobile){
        .vignette-grid {
            @include grid-distribution(1, 0, 'vignetteContain');
            .vignetteContain{
                width: 100%;
                display : flex;
                flex-flow : column nowrap;
                align-content: center;
    
                > * {
                    margin: auto;
                }
                .vignetteTitle {
                    text-align: center;
                    width : 100%;
                    margin-top : 20px;
    
                    h2 > div:first-child, a.btn:first-of-type {
                        margin-bottom : 0;
                    }
                }
            }
        }
    }
}