%text-h1{
    font-family: $site-font;
    font-size: 4.8rem;
    font-weight: 900;
    line-height: 1.2em;
    color: $core-color-font;
    
    @include RWD(mobile){
        font-size: 3rem;
    }
}

%text-h2{
    font-family: $site-font;
    color: $core-color;
    font-size: 3.3rem; 
    font-weight: 500;
    line-height: 1.2em;

    @include RWD(mobile){
        font-size: 2.2rem;
    }
}
%text-h3{
    font-family: $site-font;    
    font-weight: 700;
    font-size: 2.5rem;
    color: $second-color;
    line-height: 1.2em;
    
    @include RWD(mobile){
        font-size: 2rem;
    }
}
%text-h4{
    font-family: $site-font;    
    font-weight: 700;
    font-size: 18px;
    color: $accent-color;
    font-variant-caps: all-small-caps;
    line-height: 1.2em;

    @include RWD(mobile){
        font-size: 1.4rem;
    }
}
%text-h5{
    font-size: 1.4rem;
    line-height: 1.2em;
    text-transform: uppercase;
}
%text-hat{
    font-size: 2.5rem;
    line-height: 1.2em;
}


