%organism-wi-newsletter{
    border: 1px solid $second-color;
    background-color: #f2f2f2;
    text-align: center;
    padding: 25px;

    h3{
        @extend %atom-h3-rte;
        margin-bottom: 20px;
        & + div{
            margin-bottom: 15px;
        }
    }


    form.webform-client-form{
        width: 480px;
        max-width: 100%;
        margin: 0 auto;
        text-align: left;

        ul{
            margin-bottom: 15px;
            li{
                margin-bottom: 5px;
            }
        } 
        .form-group > .form-field{
            display: flex;
            flex-direction: row;
            
            @include RWD(mobile){
                flex-direction: column;    
            }

            input[type="email"]{
                background-color: #fff;
                color: $core-color;
                text-transform: uppercase;
                font-size: 1.5rem;
                border: none;
                font-weight: 700;
                height: 50px;
                line-height: 30px;
                padding: 10px;
                flex: 1;
                margin-right: 10px;

                @include RWD(mobile){
                    margin-right: unset;
                    margin-bottom: 10px;
                }
                
                // Placeholders
                &::-webkit-input-placeholder {
                    color: $core-color;
                    opacity: 0.5 !important;
                }
                &:-moz-placeholder{ 
                    color: $core-color;
                    opacity: 0.5 !important;
                } 
                &::-moz-placeholder { 
                    color: $core-color;
                    opacity: 0.5 !important;
                }
                &:-ms-input-placeholder {  
                    color: $core-color;
                    opacity: 0.5 !important;
                }
            }

            button{
                text-transform: uppercase;
            }
        }

    }

}