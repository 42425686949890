// BreakPoints
$break-small: 767;
$break-large: 1280; // Cette valeur doit être égale à la largeur du container + X pixel. Le passage en tablette se fera dont à X pixel de plus que la largeur du container principal, permettant de ne jamais avoir le contenu collé au bord de l'écran.
$container-width: 1240;

//~#COLORS
	$core-color: #4990a1;
	$core-color-font: #FFFFFF;
	$core-color-light: lighten($core-color,65);

	$second-color: #34313e;
	$second-color-font: #FFFFFF;

	$accent-color: #d63375;
	$accent-color-font: #FFFFFF;

	$violet-color: #7e6ba1;
	$green-color: #56baa2;
	$teal-color: #368b8c;
	
	$text-color: #69655E;
	$link-color: #505050;
 
	$success : #57c128;
	$error: #d73d38;

	$page-bg-color: #f2f2f2;
//#COLORS
 
// Fonts
$site-font: 'Supria Sans';
$main-font: 'Roboto';


// Transition
$t-bam: cubic-bezier(.85,.01,0,1);

/*IMAGES RTE*/
$image-xs: 240px;
$image-sm: 480px;
$image-md: 620px;

// Margin RTE
$margin-item-rte: 20px;
$margin-p: 20px;

$margin-h2: 25px;
$margin-h3: 25px;
$margin-h4: 20px;
$margin-h5: 15px;

$mt-h2: 2em; $mb-h2: 1em;
$mt-h3: 1.111em; $mb-h3: 0.74em;
$mt-h4: 1.666em; $mb-h4: 0.555em;
$mt-h5: 2em; $mb-h5: 1em;

// Map engine
$icon-color: $text-color;
$icon-color-active: $second-color;

// GENERIC VISUAL EFFECTS
$box-shadow: 1px 5px 6px 0px rgba(82, 81, 81, 0.31); 
$text-shadow: 1px 3px 6px rgba(82, 81, 81, 0.31); 