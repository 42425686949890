%organism-wi-banner{
    height: 76px;
    width: 100%;
    display: inline-block;
    padding: 20px 76px; 
    position: relative;
    font-size: 2.5rem;
    line-height: 1.4em;
    font-weight: 700;
    font-family: $site-font;
    text-decoration: none;
    transition: all 0.35s $t-bam;
    margin-bottom: 20px;

    @include RWD(mobile){
        height: 100%;
    }

    &:before,
    &:after{
        content: '';
        height: 76px;
        width: 76px;
        display: block;
        position: absolute;
        top: 0;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 38px 38px;
        transition: all 0.35s $t-bam;
    }
    &:before{ left: 0; }
    &:after{ right: 0; }

    &.sport-banner{
        border: 1px solid $second-color;
        background-color:  $second-color-font;
        color: $second-color;
        &:before{
            background-image: url(svg-bg-uri($svg-sport, $core-color));
        }
        &:after{
            background-image: url(svg-bg-uri($svg-big-arrow, $core-color));
        }

        &:hover, &:focus{
            border: 1px solid $accent-color;
            background-color:  #f2f2f2;
            &:before{
                background-image: url(svg-bg-uri($svg-sport, $accent-color));
            }
            &:after{
                background-image: url(svg-bg-uri($svg-big-arrow, $accent-color));
            }
        }
    }

    &.ticket-banner{
        border: 1px solid $core-color;
        background-color: $core-color;
        color: $core-color-font;
        &:before{
            background-image: url(svg-bg-uri($svg-ticket, $core-color-font));
        }
        &:after{
            background-image: url(svg-bg-uri($svg-big-arrow, $core-color-font));
        }

        &:hover, &:focus{
            border: 1px solid $accent-color;
            background-color:  #f2f2f2;
            color: $second-color;
            &:before{
                background-image: url(svg-bg-uri($svg-ticket, $accent-color));
            }
            &:after{
                background-image: url(svg-bg-uri($svg-big-arrow, $accent-color));
            }
        }

    }
}