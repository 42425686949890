// IE 11 only
_:-ms-fullscreen, :root{ 
	.page-navigation404 #main-content .link-group .link-group-item svg, .not-front .rte .link-group .link-group-item svg, .not-front #comments .link-group .link-group-item svg, .page-sitemap #site-map .link-group .link-group-item svg{
        height: 30px;
    }
    .not-front #ui-datepicker-div select,
    .class_group_intern #ui-datepicker-div select{
        background: none;
        padding: 0;
    }
    .class_group_intern .view-actualites .view-filters .webform-layout-box, .class_group_intern .view-agenda .view-filters .webform-layout-box, .class_group_intern .view-maps .view-filters .webform-layout-box, .class_group_intern.page-search-site #search-form .webform-layout-box, .class_group_intern .webform-client-form .webform-layout-box, .class_group_intern #comments form .webform-layout-box,
    .not-front .view-actualites .view-filters .webform-layout-box, .not-front .view-agenda .view-filters .webform-layout-box, .not-front .view-maps .view-filters .webform-layout-box, .not-front.page-search-site #search-form .webform-layout-box, .not-front .webform-client-form .webform-layout-box, .not-front #comments form .webform-layout-box{
        & > .form-group{
            flex: 1;
        }
    }
}