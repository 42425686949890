%atom-ul-rte{
    padding-left: 50px;
    list-style: none;
    margin-bottom: $margin-p;

    @include RWD(mobile){
        padding-left: 20px;
    }
    li{
        margin-bottom: $margin-p;
        position: relative;
        &:before{
            content: '';
            height: 5px;
            width: 5px;
            background-color: $text-color;
            border-radius: 50%;
            margin-right: 4px;
            margin-left: 2px;
            display: inline-block;
            position: relative;
            top: -3px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

%atom-ol-rte{
    padding-left: 50px;
    list-style: none;
    counter-reset: item;
    margin-bottom: $margin-p;

    @include RWD(mobile){
        padding-left: 20px;
    }
    li{
        margin-bottom: $margin-p;
        position: relative;
        
        &:before{
            content: counter(item) "";
            counter-increment: item;
            color: $text-color;
            display: inline-block;
            position: relative;
            top: 0;
            margin-right : 10px;
            font-weight: 600;
        }
        &:after {
            content: "";
            height: 5px;
            width: 5px;
            background-color:$text-color;
            border-radius: 50%;
            margin-right: 4px;
            margin-left: 2px;
            position: absolute;
            left : 10px;
            top:7px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}