#flash-banner{
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 42px;
    background-color: #fff;
    text-align:center;
    z-index: 1001;
    overflow: hidden;
    transition: all 0.35s ease;

    &.hide{
        display: none;
    }
    
    .flash-banner-wrapper{
        @extend %container;
    }
    
    a{
        display: inline-block;
        font-size: 1.6rem;
        font-weight: 500;
        width:calc(100% - 60px);
        line-height: 2rem;
        padding: 11px 0 11px 0;
        text-align: left;
        text-decoration: none;
        color: $link-color;
        vertical-align: middle;
        transition: color 0.35s ease;

        &:hover, &:focus{
            color: $accent-color;
        }
    }

    button.close{
        width: 30px;
        height: 42px;
        display: inline-block;
        background-image: url(svg-bg-uri($svg-cross, #211d70));
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px;
        vertical-align: middle;
    }
}