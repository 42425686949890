%molecule-form-warning{
    border: none;
    padding: 20px 20px 20px 85px;
    color: #9a9a9a;
    background: none; 
    background-color: #f1f1f1;
    position: relative;

    &:before{
        position: absolute;
        left: 20px;
        top: 20px;
        content: '';
        height: 45px;
        width: 45px;
        display: block;
        background-position: center;
        background-size: auto 55px;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-exclamation, $core-color) );
        border: solid 2px $core-color;
        border-radius: 50%;
    }

    h2{
        display: block;
        font-size: 20px;
        font-size: 2.0rem;
        color: #7e8c8d;
        margin-bottom: 20px;
        font-weight: 500;
        margin-top: .6em;
    }
    ul{
        @extend %atom-ul-rte;
    }
    & > *:last-child{
        margin-bottom: 0;
    }
}